import './cost.sass'
import './table.sass'
import 'jquery-ui-dist/jquery-ui.min'
import 'jquery-ui-dist/jquery-ui.min.css'
import 'ion-rangeslider'
import 'ion-rangeslider/css/ion.rangeSlider.css'
import 'ion-rangeslider/css/ion.rangeSlider.skinHTML5.css'

export default () => {

  $('#tabs').tabs({
    hide: { effect: "fadeOut", duration: 200 },
    show: { effect: "fadeIn", duration: 200 },
  });

  $('#tabs-1 .range').ionRangeSlider({
    type: "single",
    grid: true,
    min: "0",
    max: "500",
    grid_snap: true,
    step: 5,
    hide_min_max: true,
    hide_from_to: true,
    drag_interval: true,
    prettify_enabled: true,
    prettify: function (num) {
      return `${num} м<sup>2</sup>`
    },
    onFinish: function (data) {
      $('.table--widescreen .table__col--small, .table--widescreen .table__col--middle').each(function (index, item) {
        let cost = $(this).data('v-0');
        let $home = $(this).find('span');
        let value = data.from;
        if(value < 50) {
          $home.text(cost);
        } else if(value >= 50 && value < 100) {
          $home.text((cost - (cost * 0.1)).toFixed(2));
        } else if(value >= 100 && value < 250) {
          $home.text((cost - (cost * 0.12)).toFixed(2));
        } else if(value >= 250 && value < 350) {
          $home.text((cost - (cost * 0.18)).toFixed(2));
        } else if(value >= 350) {
          $home.text((cost - (cost * 0.23)).toFixed(2));
        }
      });
    }
  });

  $('#tabs-2 .range').ionRangeSlider({
    type: "single",
    grid: true,
    min: "0",
    max: "500",
    grid_snap: true,
    step: 5,
    hide_min_max: true,
    hide_from_to: true,
    drag_interval: true,
    prettify_enabled: true,
    prettify: function (num) {
      return `${num} м<sup>2</sup>`
    },
    onFinish: function (data) {
      $('.table--interior .table__col--small, .table--interior .table__col--middle').each(function (index, item) {
        let cost = $(this).data('v-0');
        let $home = $(this).find('span');
        let value = data.from;
        if(value < 50) {
          $home.text(cost);
        } else if(value >= 50 && value < 100) {
          $home.text((cost - (cost * 0.1)).toFixed(2));
        } else if(value >= 100 && value < 250) {
          $home.text((cost - (cost * 0.15)).toFixed(2));
        } else if(value >= 250 && value < 350) {
          $home.text((cost - (cost * 0.2)).toFixed(2));
        } else if(value >= 350) {
          $home.text((cost - (cost * 0.25)).toFixed(2));
        }
      });
    }
  });
}
