import 'babel-polyfill';

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js').default;

import './fonts'
import initLayout from './layout'
import initPages from './pages'
import initBlocks from './blocks/index'

class Application{
  constructor(){
    initLayout();
    initPages();
    initBlocks();
  }
}

let app = null;

$(function () {
  app = new Application();
});
