import './varieties.sass'
import initSlider from './varieties-more/index'
import 'jquery-ui-dist/jquery-ui.min'
import 'jquery-ui-dist/jquery-ui.min.css'

export default () => {
  initSlider();

  $('.varieties__tabs').each(function (index, item) {
    $(item).tabs({
      collapsible: true,
      active: false
    });
  });

  $('.varieties-more__questions, .varieties-more__costing').on('click', function (event) {
    event.preventDefault();
    var id  = $(this).attr('href'),
      top = $(id).offset().top;
    $('body,html').animate({scrollTop: top}, 500);
  });

}