import initHome from './home'
import initCalc from './calc'
import 'jquery-mask-plugin/dist/jquery.mask.min'

export default () => {
  initHome();
  initCalc();

  let $form = $('.js-send-email');
  let $formCalc = $('.js-send-calc-email');

  $form.on('submit', function (e) {
    e.preventDefault();
    let clickForm = $(this);

    $.ajax({
      url: clickForm.attr('action'),
      method: clickForm.attr('method'),
      data: clickForm.serialize()
    }).done(function () {
      clickForm.find('[type="submit"]').text('Письмо отправлено!');
    }).fail(function () {
      alert('Произошла ошибка при отправке письма');
    })
  });

  $formCalc.on('submit', function (e) {
    e.preventDefault();
    let clickForm = $(this);

    $.ajax({
      url: clickForm.attr('action'),
      method: clickForm.attr('method'),
      data: clickForm.serialize()
    }).done(function () {
      clickForm.find('.calculator__in button').fadeOut(300);
      clickForm.find('.calculator__in-text').slideDown(500);
    }).fail(function () {
      alert('Произошла ошибка при отправке письма');
    })
  });

  let count = $('.about__count-c').text();
  let hours = 24;
  let now = Date.now();
  let setupTime = localStorage.getItem('setupTime');

  if (setupTime == null) {
    localStorage.setItem('setupTime', now);
  } else {
    if(now - setupTime > hours*60*60*1000) {
      localStorage.clear();
      localStorage.setItem('setupTime', now);
      count += 266;
      $('.about__count-c').text(count);
    }
  }

  $('input[name="phone"]').mask('+7 (000) 000 - 00 - 00');

}