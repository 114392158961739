import './header.sass'
import 'magnific-popup/dist/jquery.magnific-popup.min'
import 'magnific-popup/dist/magnific-popup.css'

export default () => {

  $('.header__nav a').on('click', function (event) {
    var id  = $(this).attr('href'),
      top = $(id).offset().top;
    if($(window).width() <= 991) {
      top -= 70
    }
    $('body,html').animate({scrollTop: top}, 500);
    $('.header__burger, .header__content').removeClass('open');
  });

  $('.header__button a').magnificPopup({
    type:'inline',
    midClick: true
  });

  $('.header__burger').on('click', function () {
    $(this).toggleClass('open');
    $('.header__content').toggleClass('open');
  });

}