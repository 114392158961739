import './calculator.sass'
import 'jquery-form-styler'
import 'jquery-form-styler/dist/jquery.formstyler.css'
import 'jquery-form-styler/dist/jquery.formstyler.theme.css'
import * as materialsOfType from './materials'

let resolutionList = {
  large: ['360', '720'],
  interior: ['1440']
};

const calculate = () => {
  let $type = $('#type');
  let $materials = $('#material');
  let $resolutions = $('#resolution');

  let materialId = $materials.val();
  let resolution = $resolutions.val();

  let material = materialsOfType[$type.val()].find((material) => {
    return material.title == materialId
  });

  let $form = $('.calculator form');
  let width = +$form.find('#width').val();
  let height = +$form.find('#height').val();
  let size = $form.find('#size').val();
  let eyelet = $form.find('#eyelet').is(':checked');
  let luv = $form.find('#luv').val();
  let mounting = $form.find('#mounting').is(':checked');
  let $gluing = $form.find('#gluing');
  let gluingStatus = $gluing.is(':checked');
  let type = $form.find('#type').val();
  let $luvW = $('#luvW');
  let $luvH = $('#luvH');
  let $luvP = $('#luvP');
  let $gluW = $('#gluW');
  let $gluH = $('#gluH');
  let $gluP = $('#gluP');
  let $luvDiameter = $('input[name="luvDiameter"]');

  let price = material.resolution[resolution];

  if(price) {
    if(width && height) {
      $('.calculator__sum-t').css('display', 'inline-block');
      $('.calculator__sum-text').css('display', 'block');

      let luvPrice = 0;

      $('.calculator__right ul li.sizes span.width').html(width);
      $('.calculator__right ul li.sizes span.height').html(height);

      if (size == 'см') {
        width = width / 100;
        height = height / 100;
      } else if (size == 'мм') {
        width = width / 1000;
        height = height / 1000;
      }

      if(eyelet) {

        let diameterCoef;
        if($('#luv10').is(':checked')) {
          diameterCoef = 10;
        } else if($('#luv16').is(':checked')) {
          diameterCoef = 16;
        }

        if(luv) {
          if (size == 'см') {
            luv = luv / 100;
          } else if (size == 'мм') {
            luv = luv / 1000;
          }
          let valueLuverse = 0;
          if($('#luvW').is(':checked')) {
            valueLuverse = ((width * 2) / luv).toFixed();
            luvPrice = valueLuverse * diameterCoef;
          } else if($('#luvH').is(':checked')) {
            valueLuverse = ((height * 2) / luv).toFixed();
            luvPrice = valueLuverse * diameterCoef;
          } else {
            valueLuverse = (width + height) * 2 / luv;
            luvPrice = valueLuverse * diameterCoef;
          }
        }
      }

      let square = width * height;

      price = price * square;

      if(square < 50){
        (price).toFixed(2);
      } else if (square >= 50 && square < 100) {
        (price -= price * 0.1).toFixed(2);
      } else if (square >= 100 && square < 250) {
        (price -= price * 0.12).toFixed(2);
      } else if (square >= 250 && square < 350) {
        (price -= price * 0.18).toFixed(2);
      } else if (square >= 350) {
        (price -= price * 0.23).toFixed(2);
      }


      // склейка
      let priceLess = 120;
      let priceMore = 70;

      let maxSide = 0;
      let minSide = 0;
      if(width >= height) {
        maxSide = width;
        minSide = height;
      } else if(width <= height) {
        maxSide = height;
        minSide = width;
      }

      if(material.title === 'Баннерная ткань 300 г/м3 MPF' ||
        material.title === 'Баннерная ткань 340 г/м3' ||
        material.title === 'Баннерная ткань 440 г/м3 MPF' ||
        material.title === 'Баннерная ткань 510 г/м3 Starflex' ||
        material.title === 'Баннерная ткань транслюцентная' ||
        material.title === 'Литой 510 г/м3' ||
        material.title === 'Сетка') {
        if(material.title === 'Сетка') {
          priceLess = 70;
        }
        if(type == 'large') {
          if (minSide > 3) {
            if (minSide <= 6) {
              price += Math.floor(maxSide / 3.1) * minSide * priceLess;
              if(material.title === 'Сетка') {
                $('.calculator__right li.bonding').addClass('hide');
                $('.calculator__right li.welding').removeClass('hide');
              } else {
                $('.calculator__right li.bonding').removeClass('hide');
                $('.calculator__right li.welding').addClass('hide');
              }
            } else if (height > 6) {
              price += Math.floor(maxSide / 3.1) * minSide * priceMore;
              if(material.title === 'Сетка') {
                $('.calculator__right li.bonding').addClass('hide');
                $('.calculator__right li.welding').removeClass('hide');
              } else {
                $('.calculator__right li.bonding').addClass('hide');
                $('.calculator__right li.welding').removeClass('hide');
              }
            }
          } else {
            $('.calculator__right li.bonding').addClass('hide');
            $('.calculator__right li.welding').addClass('hide');
          }
        } else if(type == 'interior') {
          if(minSide > 1.55) {
            if(minSide <= 6) {
              price += Math.floor(maxSide / 1.61) * minSide * priceLess;
            } else if(height > 6) {
              price += Math.floor(maxSide / 1.61) * minSide * priceMore;
            }
          } else {
            $('.calculator__right li.bonding').addClass('hide');
            $('.calculator__right li.welding').addClass('hide');
          }
        }
      } else {
        $('.calculator__right li.bonding').addClass('hide');
        $('.calculator__right li.welding').addClass('hide');
      }

      if(width <= 2.5 || height <= 2.5) {
        let minS = 0;
        let maxS = 0;
        if(width >= height) {
          minS = height;
          maxS = width;
        } else {
          minS = width;
          maxS = height;
        }
        if(material.title === 'Баннерная ткань 300 г/м3 MPF') {
          price += ((3 - minS) * maxS) * 75;
        } else if(material.title === 'Баннерная ткань 340 г/м3') {
          price += ((3 - minS) * maxS) * 85;
        } else if(material.title === 'Баннерная ткань 510 г/м3 Starflex') {
          price += ((3 - minS) * maxS) * 130;
        } else if(material.title === 'Сетка') {
          price += ((3 - minS) * maxS) * 130;
        } else if(material.title === 'Литой 510 г/м3') {
          price += ((3 - minS) * maxS) * 130;
        }
      }


      // проклейка
      if(gluingStatus) {
        if($gluW.is(':checked')) {
          price += (width * 2) * 25;
        } else if($gluH.is(':checked')) {
          price += (height * 2) * 25;
        } else {
          price += ((width + height) * 2) * 25;
        }
      }

      if(mounting) {
        price = price + (square * 200);
      }

      let finishPrice = (price + luvPrice).toFixed(2);
      $form.find('input[name="price"]').val(finishPrice);
      $('.calculator__message').addClass('active');
      $('.calculator__sum-c').text(`${finishPrice} ₽`);

    } else {
      $('.calculator__sum-c').html('Введите размер материала');
      $('.calculator__sum-text').css('display', 'none');
      $('.calculator__sum-t').css('display', 'none');
    }
  } else {
    $('.calculator__sum-c').html('Разрешение отсутствует');
  }

  $('.calculator__right ul li.material').html(material.title);
  $('.calculator__right ul li.resolution').html($resolutions.find(':selected').text());
  $('.calculator__right ul li.sizes span.size').html(size);

  if(eyelet) {
    $('li.eyelet').removeClass('hide');
    $('li.gluW').addClass('hide');
    $('li.gluH').addClass('hide');
  } else {
    $('li.eyelet').addClass('hide');
  }
  if(luv) {
    $('li.step').removeClass('hide');
    $('.calculator__right ul li.step span').text(luv);
  } else {
    $('li.step').addClass('hide');
  }


  if($luvW.is(':checked')) {
    $('li.luvW').removeClass('hide');
    $('li.luvH').addClass('hide');
    $('li.eyelet').addClass('hide');
  } else {
    $('li.luvW').addClass('hide');
  }

  if($luvH.is(':checked')) {
    $('li.luvW').addClass('hide');
    $('li.luvH').removeClass('hide');
    $('li.eyelet').addClass('hide');
  } else {
    $('li.luvH').addClass('hide');
  }

  if($('#luv10').not(':checked') && $('#luv16').not(':checked')) {
    $('li.luvDiameter').addClass('hide');
  }

  if($('#luv10').prop('checked')) {
    $('li.luvDiameter').removeClass('hide');
    $('.calculator__right ul li.luvDiameter span').text($('#luv10').val());
  }

  if($('#luv16').prop('checked')) {
    $('li.luvDiameter').removeClass('hide');
    $('.calculator__right ul li.luvDiameter span').text($('#luv16').val());
  }

  if(gluingStatus) {
    $('li.gluing').removeClass('hide');
    $('#luvW').prop('checked', false).trigger('refresh');
    $('#luvH').prop('checked', false).trigger('refresh');
    $('li.luvW, li.luvH').addClass('hide');
  } else {
    $('li.gluing').addClass('hide');
  }

  if($gluW.is(':checked')) {
    $('li.gluW').removeClass('hide');
    $('li.gluH').addClass('hide');
    $('li.gluing').addClass('hide');
  } else {
    $('li.gluW').addClass('hide');
  }

  if($gluH.is(':checked')) {
    $('li.gluH').removeClass('hide');
    $('li.gluW').addClass('hide');
    $('li.gluing').addClass('hide');
  } else {
    $('li.gluH').addClass('hide');
  }

  if(mounting) {
    $('li.mounting').removeClass('hide');
  } else {
    $('li.mounting').addClass('hide');
  }
  $('.calculator__right').addClass('active');

};



export default () => {

  $('.calculator select, input[type="checkbox"], input[type="radio"]').styler();

  $('.home__calculator .calculator__field--calculate button').prop('disabled', true);
  $('#name, #phone').on('change', function () {
    if($('#name').val() && $('#phone').val().length > 21) {
      $('.home__calculator .calculator__field--calculate button').prop('disabled', false);
    } else {
      $('.home__calculator .calculator__field--calculate button').prop('disabled', true);
    }
  });

  let $type = $('#type');
  let $materials = $('#material');
  let $resolutions = $('#resolution');
  let $eyelet = $('#eyelet');
  let $luv = $('#luv');
  let $gluing = $('#gluing');
  let $luvW = $('#luvW');
  let $luvH = $('#luvH');
  let $luvP = $('#luvP');
  let $gluW = $('#gluW');
  let $gluH = $('#gluH');
  let $gluP = $('#gluP');

  $luvW.on('change', function () {
    if($(this).is(':checked')) {
      $luvH.prop('checked', false).trigger('refresh');
      $luvP.prop('checked', false).trigger('refresh');
    }
  });

  $luvH.on('change', function () {
    if($(this).is(':checked')) {
      $luvW.prop('checked', false).trigger('refresh');
      $luvP.prop('checked', false).trigger('refresh');
    }
  });

  $luvP.on('change', function () {
    if($(this).is(':checked')) {
      $luvW.prop('checked', false).trigger('refresh');
      $luvH.prop('checked', false).trigger('refresh');
    }
  });

  $gluW.on('change', function () {
    if($(this).is(':checked')) {
      $gluH.prop('checked', false).trigger('refresh');
      $gluP.prop('checked', false).trigger('refresh');
    }
  });

  $gluH.on('change', function () {
    if($(this).is(':checked')) {
      $gluW.prop('checked', false).trigger('refresh');
      $gluP.prop('checked', false).trigger('refresh');
    }
  });

  $gluP.on('change', function () {
    if($(this).is(':checked')) {
      $gluW.prop('checked', false).trigger('refresh');
      $gluH.prop('checked', false).trigger('refresh');
    }
  });

  $('#size').on('change', function () {
    $('.calculator__field--luv span').text($(this).val());
  });


  $eyelet.on('change', function () {
    if($(this).is(':checked')) {
      $('.calculator__gluing-side').slideUp();
      $('.calculator__step').slideDown();
      $gluing.prop('checked', false).trigger('refresh');
      $gluing.val('');
      $gluW.prop('checked', false).trigger('refresh');
      $gluH.prop('checked', false).trigger('refresh');
      $('#luv10').prop('checked', true).trigger('refresh');
    } else {
      $('.calculator__step').slideUp();
      $luv.val('');
      $luvW.prop('checked', false).trigger('refresh');
      $luvH.prop('checked', false).trigger('refresh');
      $('#luv10').prop('checked', false).trigger('refresh');
      $('#luv16').prop('checked', false).trigger('refresh');
    }
  });

  $gluing.on('change', function () {
    if($(this).is(':checked')) {
      $eyelet.prop('checked', false).trigger('refresh');
      $('.calculator__step').slideUp();
      $('.calculator__gluing-side').slideDown();
      $luv.val('');
      $luvW.prop('checked', false).trigger('refresh');
      $luvH.prop('checked', false).trigger('refresh');
      $('#luv10').prop('checked', false).trigger('refresh');
      $('#luv16').prop('checked', false).trigger('refresh');
    } else {
      $('.calculator__gluing-side').slideUp();
      $gluW.prop('checked', false).trigger('refresh');
      $gluH.prop('checked', false).trigger('refresh');
    }
  });

  $type.on('change', function () {
    let $this = $(this);

    $materials.html(
      materialsOfType[$this.val()]
        .map((mater) => `<option value="${mater.title}">${mater.title}</option>`)
        .join('')
    ).trigger('refresh');

    $resolutions.html(
      resolutionList[$this.val()]
        .map((res) => `<option value="${res}">${res} dpi</option>`)
        .join('')
    ).trigger('refresh');
  });

  $materials.append(
    materialsOfType['large'].reduce((acc, material) => {
      acc = acc + `<option value="${material.title}">${material.title}</option>`;
      return acc;
    }, "")
  ).trigger('refresh');

  $('.calculator button[type="button"]').on('click', function(e) {
    calculate();
  });

  $('.calculator form').on('submit', function(e) {
    e.preventDefault();
    calculate();
  });

}