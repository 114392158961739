import './index.sass'
import './index.pug'
import './equipment'
import './feedback'
import initIntro from './intro/index'
import initAbout from './about/index'
import initVarieties from './varieties/index'
import initCost from './cost/index'

export default () => {
  initIntro();
  initAbout();
  initVarieties();
  initCost();
}
