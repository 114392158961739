import './about.sass'

export default () => {

  function isIE() {
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    return is_ie;
  }

  if(isIE()) {
    $('.about__count').css('background', 'none');
  }

  let data = JSON.parse( localStorage.getItem('data') );
  let now = Date.now();
  let interval = 86400000;

  if(data) {
    if(now > data.date + interval) {
      localStorage.setItem('data', JSON.stringify({
        date: now - ( (now - data.date) % interval ),
        value: data.value + 266 * Math.floor( (now - data.date) / interval )
      }));
    }
  } else {
    localStorage.setItem('data', JSON.stringify({
      date: now,
      value: 1000000
    }));
  }
  $('.about__count span').text(JSON.parse( localStorage.getItem('data') ).value);
}