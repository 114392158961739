import './varieties-more.sass'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default () => {
  $('.varieties-more__slider').slick({
    responsive: [
      {
        breakpoint: 479,
        settings: {
          dots: true,
          arrows: false
        }
      }
    ]
  });
}