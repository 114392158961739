const large = [
  {
    id: 1,
    title: "Баннерная ткань 300 г/м3 MPF",
    resolution: {
      ["360"]: 145,
      ["720"]: 250
    }
  },
  {
    id: 2,
    title: "Баннерная ткань 340 г/м3",
    resolution: {
      ["360"]: 160,
      ["720"]: 300
    }
  },
  {
    id: 3,
    title: "Баннерная ткань 440 г/м3 MPF",
    resolution: {
      ["360"]: 210,
      ["720"]: 330
    }
  },
  {
    id: 4,
    title: "Баннерная ткань 510 г/м3 Starflex",
    resolution: {
      ["360"]: 240,
      ["720"]: 360
    }
  },
  {
    id: 5,
    title: "Баннерная ткань транслюцентная",
    resolution: {
      ["360"]: 355,
      ["720"]: 500
    }
  },
  {
    id: 6,
    title: "Литой 510 г/м3",
    resolution: {
      ["360"]: 250,
      ["720"]: 370
    }
  },
  {
    id: 7,
    title: "Сетка",
    resolution: {
      ["360"]: 260,
      ["720"]: 0
    }
  },
  {
    id: 8,
    title: "Самоклейка Импорт",
    resolution: {
      ["360"]: 240,
      ["720"]: 340
    }
  },
  {
    id: 9,
    title: "Самоклейка Европа",
    resolution: {
      ["360"]: 250,
      ["720"]: 360
    }
  },
  {
    id: 10,
    title: "Самоклейка Европа (светопрозрачная)",
    resolution: {
      ["360"]: 250,
      ["720"]: 360
    }
  },
  {
    id: 11,
    title: "Самоклейка Европа транслюцентная",
    resolution: {
      ["360"]: 425,
      ["720"]: 550
    }
  },
  {
    id: 11,
    title: "Литой 510 г/м3",
    resolution: {
      ["360"]: 250,
      ["720"]: 370
    }
  },
  {
    id: 12,
    title: "Перфопленка",
    resolution: {
      ["360"]: 655,
      ["720"]: 0
    }
  },
  {
    id: 13,
    title: "Самоклейка Европа траслюцентная",
    resolution: {
      ["360"]: 105,
      ["720"]: 180
    }
  }
];

const interior = [
  {
    id: 14,
    title: "Баннерная ткань 440 г/м3 MPF",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 650
    }
  },
  {
    id: 15,
    title: "Самоклейка Европа",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 700
    }
  },
  {
    id: 16,
    title: "Самоклейка Европа (светопрозрачная)",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 700
    }
  },
  {
    id: 17,
    title: "Самоклейка Европа траслюцентная",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 850
    }
  },
  {
    id: 18,
    title: "Баннерная ткань транслюцентная",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 700
    }
  },
  {
    id: 19,
    title: "Холст натуральный",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 1500
    }
  },
  {
    id: 20,
    title: "Фотобумага",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 750
    }
  },
  {
    id: 21,
    title: "Фотообои",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 900
    }
  },
  {
    id: 22,
    title: "Перфопленка",
    resolution: {
      ["360"]: 0,
      ["720"]: 0,
      ["1440"]: 950
    }
  }
];

export { large, interior }